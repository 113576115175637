<template>
  <div class="wrap question px-md-6 py-md-4 px-4 py-3" v-scroll="handleScroll">
    <!-- <div v-if="!load" class="load text-center mt-10">
      <v-progress-circular indeterminate color="secondary" size="200" width="16"></v-progress-circular>
    </div> -->
    <v-skeleton-loader v-if="!load" type="list-item-avatar-two-line, list-item-two-line, image, list-item"></v-skeleton-loader>
    <v-card v-else-if="error == 403" tile flat class="mt-10 text-center primary--text">
      <img class="empty-img" src="@/assets/img/empty.svg" alt="empty" />
      <div class="text-h5 font-weight-black mt-3">{{ $t('questionItem.noPost.title') }}</div>
      <div class="mt-1 text-block">{{ $t('questionItem.noPost.desc') }}</div>
    </v-card>
    <v-card v-else-if="error >= 500" tile flat class="mt-10 text-center primary--text">
      <img class="empty-img" src="@/assets/img/empty.svg" alt="empty" />
      <div class="text-h5 font-weight-black mt-3">{{ $t('questionItem.noFound.title') }}</div>
      <div class="mt-1 text-block">{{ $t('questionItem.noFound.desc') }}</div>
    </v-card>
    <v-card v-else tile flat class="primary--text" id="content">
      <div class="d-flex align-center">
        <v-avatar size="60" color="accent">
          <v-img cover v-if="data.created_by.picture" :src="data.created_by.picture" alt="avatar"></v-img>
          <v-icon v-else>mdi-account mdi-36px</v-icon>
        </v-avatar>
        <div class="ml-4">
          <div class="font-weight-medium f18">
            {{ data.created_by.first_name }} {{ data.created_by.last_name }}
            <span class="f15 gray--text text--lighten-5">
              <v-card flat color="d-inline-block gray lighten-5 rounded-circle mx-2 mb-1" width="4" height="4"></v-card>
              {{
                new Date(data.created_at).toLocaleString(select.key == 'en' ? 'en-US' : 'ar-EG', {
                  day: 'numeric',
                  year: 'numeric',
                  month: 'short',
                })
              }}
            </span>
          </div>
          <div class="d-flex flex-wrap" v-if="data.created_by.cars.length">
            <div
              v-for="(item, j) in data.created_by.cars"
              :key="item.id"
              class="cars-item"
              :class="data.created_by.cars.length <= 4 ? '' : j > 2 ? 'd-none' : ''"
              :style="`z-index: ${5 - j}`"
            >
              <img v-if="item.vendor" class="d-block rounded-circle" height="24" width="24" :src="item.vendor.icon" alt="icon" />
              <v-card flat v-else class="gray lighten-4 rounded-circle" width="24" height="24"></v-card>
            </div>
            <div class="topic-item primary--text text-body-2" v-if="data.created_by.cars.length - 4 > 0">
              +{{ data.created_by.cars.length - 3 }}
            </div>
          </div>
          <!-- <div class="gray--text f15">
            {{ data.created_by.country ? data.created_by.country.name : 'Unknown' }}
            {{ data.created_by.city ? `, ${data.created_by.city.name}` : '' }}
            <span class="vert mx-2"></span>
            {{ new Date(data.created_at).toLocaleString('en-US', { day: 'numeric', year: 'numeric', month: 'short' }) }}
          </div> -->
        </div>
      </div>
      <div class="d-flex align-center mt-3">
        <div v-if="data.is_answered" class="answered mr-3">Answered</div>
        <div class="gray--text">
          In: <span class="blue--text"> {{ data.category.name }}</span>
        </div>
      </div>
      <div class="d-flex flex-wrap">
        <div v-for="i in data.topics" :key="i.id" class="topic-item">
          <img v-if="i.icon" class="d-block" height="24" width="24" :src="i.icon" alt="icon" />
          <v-card flat v-else class="gray lighten-4 rounded-circle" width="24" height="24"></v-card>
          <div class="ms-2">{{ i.title }}</div>
        </div>
      </div>
      <div class="mt-4 mb-1 post-text">{{ data.text }}</div>
      <v-carousel
        v-model="slide"
        v-if="data.attachments.length"
        :hide-delimiters="data.attachments.length <= 1"
        hide-delimiter-background
        :show-arrows="false"
        :height="healthAtt"
        prev-icon="mdi-chevron-left-circle"
        next-icon="mdi-chevron-right-circle"
        style="border-radius: 10px"
      >
        <!-- <template v-slot:prev="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="healthImg(--slide)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="healthImg(++slide)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template> -->
        <v-carousel-item v-for="(item, i) in data.attachments" :key="item.id">
          <v-img v-if="item.type == 'picture'" @load="healthImg(i)" cover :width="'100%'" :src="item.url"></v-img>
          <VideoPlayer v-else :options="optionVideo(item)" />
        </v-carousel-item>
      </v-carousel>
      <!-- <div class="navigate-dot" v-if="data.attachments.length > 1">
        <div v-for="(item, i) in data.attachments" :key="item.id">
          <v-icon class="text-body-2" :color="slide == i ? 'blue' : 'gray lighten-5'" @click="healthImg(i)">mdi-circle-medium</v-icon>
        </div>
      </div> -->
      <div class="mt-1 d-flex">
        <div class="d-flex align-center mr-3">
          <img src="@/assets/icon/like.svg" alt="like" />
          <div class="gray--text ml-3 text-body-2 font-weight-medium">{{ data.votes }}</div>
        </div>
        <div class="d-flex align-center">
          <img src="@/assets/icon/diz.svg" alt="dizlike" />
        </div>
        <!-- <v-spacer></v-spacer> -->
        <!-- <img class="link" src="@/assets/icon/share.svg" alt="share" /> -->
      </div>
      <div class="mt-5">
        <div class="text-body-2" style="font-weight: 600">{{ $t('allAnswer') }} ({{ answersList.total }})</div>
        <div class="mt-3 text-body-2" v-for="item in array" :key="item.id">
          <div class="d-flex mt-3">
            <div>
              <v-avatar size="42" color="accent" :class="item.is_correct ? 'ava' : ''">
                <v-img cover v-if="item.created_by.picture" :src="item.created_by.picture" alt="avatar"></v-img>
                <v-icon v-else>mdi-account mdi-36px</v-icon>
              </v-avatar>
              <img v-if="item.is_correct" class="mx-auto d-block mt-n1" height="30" src="@/assets/icon/true.svg" alt="correct" />
            </div>
            <div class="ml-2">
              <div class="font-weight-medium text-body-1">{{ item.created_by.first_name }} {{ item.created_by.last_name }}</div>
              <div>
                <span class="gray--text">
                  {{ item.created_by.country ? item.created_by.country.name : 'Unknown' }}
                  {{ item.created_by.city ? `, ${item.created_by.city.name}` : '' }}
                </span>
                <span class="vert mx-2"></span>
                <span class="gray--text">
                  {{
                    new Date(item.created_at).toLocaleString(select.key == 'en' ? 'en-US' : 'ar-EG', {
                      day: 'numeric',
                      year: 'numeric',
                      month: 'short',
                    })
                  }}
                </span>
              </div>
              <div class="my-2 f15">{{ item.text }}</div>
              <v-img v-if="item.attachments.length" class="rounded-lg" contain max-width="160" height="auto" :src="item.attachments[0].url">
              </v-img>
            </div>
          </div>
          <div class="mt-3 d-flex">
            <div class="d-flex align-center mr-3">
              <img src="@/assets/icon/like.svg" alt="like" />
              <div class="gray--text ml-3 text-body-2 font-weight-medium">{{ item.votes }}</div>
            </div>
            <div class="d-flex align-center">
              <img src="@/assets/icon/diz.svg" alt="dizlike" />
            </div>
          </div>
        </div>
        <div class="text-center mt-8">
          <v-btn v-if="page != answersList.pages - 1 && !!answersList.pages" @click="++page, getAnswers()" text color="gray">
            <v-icon color="#E0E0E0">mdi-plus</v-icon><span class="f15">{{ $t('btn.moreAnswer') }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card
      :width="width <= 1280 ? '310' : '318'"
      outlined
      color="accent"
      class="pa-lg-6 pa-4 pb-lg-8 pb-6 app d-md-block d-none"
      :style="`top: ${scrollPage >= 72 ? '12' : '84'}px`"
    >
      <div class="primary--text text-h5 font-weight-black">{{ $t('apps.title') }}</div>
      <div class="primary--text mt-1">{{ $t('apps.desc') }}</div>
      <div class="mt-8 d-flex justify-space-between flex-wrap">
        <a href="https://apps.apple.com/us/app/snobar/id1613143471" target="_blank" class="d-block" style="height: 40px">
          <img
            height="40"
            width="120"
            :src="require(`@/assets/icon/apple${select.key == 'ar' ? '-ar' : ''}.png`)"
            alt="apple"
            :srcset="getImgSet(select.key == 'ar' ? 'apple-ar' : 'apple')"
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=app.snobar.client" target="_blank" class="d-block" style="height: 40px">
          <img
            height="40"
            width="120"
            :src="require(`@/assets/icon/google${select.key == 'ar' ? '-ar' : ''}.png`)"
            alt="google"
            :srcset="getImgSet(select.key == 'ar' ? 'google-ar' : 'google')"
          />
        </a>
      </div>
    </v-card>
  </div>
</template>

<script>
import VideoPlayer from '@/components/player.vue';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    VideoPlayer,
  },
  data() {
    return {
      load: false,
      page: 0,
      scrollPage: 0,
      slide: 0,
      healthAtt: 270,
      array: [],
      error: null,
    };
  },
  mounted() {
    this.getPost();
    this.getAnswers();
  },
  methods: {
    getPost() {
      this.$store
        .dispatch('getQuestion')
        .catch((e) => {
          this.error = e.response.status;
          if (this.error == 404) {
            this.$router.push('/404');
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    async getAnswers() {
      await this.$store.dispatch('getQuestionAnswers', this.page);
    },
    healthImg(i) {
      i < 0 ? (this.slide = this.data.attachments.length - 1) : i > this.data.attachments.length - 1 ? (this.slide = 0) : (this.slide = i);
      // const w = this.data.attachments[this.slide].width;
      // const h = this.data.attachments[this.slide].height;
      // this.$nextTick(() => {
      //   this.healthAtt = (document.getElementById('content').clientWidth / w) * h;
      // });
    },
    optionVideo(i) {
      const f = i.url.substr(-4);
      return {
        autoplay: false,
        controls: true,
        sources: [{ src: i.url, type: f == 'm3u8' ? 'application/x-mpegURL' : 'video/mp4' }],
      };
    },
    handleScroll() {
      this.scrollPage = window.pageYOffset;
    },
    getImgSet(img) {
      return `
        ${require(`@/assets/icon/${img}-2x.png`)} 2x,
        ${require(`@/assets/icon/${img}-3x.png`)} 3x
      `;
    },
  },
  watch: {
    answersList(oldVal, newVal) {
      if (oldVal.pages == 0) {
        this.array = [];
      } else if (oldVal.page != newVal.page) {
        for (let i = 0; i < this.answersList.results.length; i++) {
          this.array.push(this.answersList.results[i]);
        }
      } else {
        this.array = [];
        for (let i = 0; i < this.answersList.results.length; i++) {
          this.array.push(this.answersList.results[i]);
        }
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.question;
    },
    answersList() {
      return this.$store.getters.questionAnswersList;
    },
    select() {
      return this.$store.getters.langValue;
    },
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
  destroyed() {
    this.$store.dispatch('setQuestion', { created_by: { city: {}, country: {} }, attachments: [], category: {} });
    this.$store.dispatch('setQuestionAnswers', {});
  },
};
</script>

<style lang="scss">
.question {
  min-height: calc(100vh - 60px);
  .answered {
    color: white;
    background: #16a696;
    border-radius: 37px;
    padding: 6px 10px;
  }
  .ava {
    border: 2px solid #16a696 !important;
  }
  .empty-img {
    object-fit: cover;
    max-width: 335px;
    width: 100%;
  }
  .load {
    height: 400px;
  }
  .vert {
    display: inline-block;
    width: 1px;
    height: 12px;
    background: #e0e0e0;
  }
  .app {
    position: fixed;
    top: 84px;
    left: calc(50% + 300px);
    border-radius: 10px !important;
    border-color: #e0e0e0 !important;
  }
  .text-block {
    max-width: 340px;
    width: 100%;
    margin: 0 auto;
  }
  @media screen and (max-width: 1240px) {
    .app {
      display: none !important;
    }
  }
  @media screen and (max-width: 960px) {
    min-height: calc(100vh - 42px);
  }
  @media screen and (max-width: 560px) {
    .empty-img {
      padding: 0 16px;
    }
  }
  .v-carousel__controls {
    left: 50% !important;
    transform: translate(-50%, 0);
    width: initial;
    .v-carousel__controls__item {
      margin: 0 2px;
    }
    .v-carousel__controls__item .v-icon {
      opacity: 1;
    }
    .btn--active::before {
      display: none !important;
    }
    .v-btn--icon.v-size--small,
    .v-btn--icon.v-size--small .v-icon,
    .v-btn--fab.v-size--small .v-icon {
      height: 10px;
      width: 10px;
      font-size: 10px !important;
    }
    .v-btn--active {
      color: var(--v-blue-base);
    }
  }
}
.v-application--is-rtl {
  .question {
    .app {
      right: calc(50% + 300px);
      left: initial;
    }
  }
}
</style>
